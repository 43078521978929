import React from 'react'

import bgDev from '../../assets/images/background-tower.jpg';

export const FirstSection = () => {

  const scrollTo = (section) => {
    var rec = document.getElementById(section).getBoundingClientRect();
    return window.scroll({left:0, top:rec.top + window.scrollY - 0, behavior: 'smooth'});
}

  return (
    <section className="main__first__section__novedades d-flex" id="firstSection">
      <div className="main__first__section__container__novedades">
        <img className="main__first__section__background__novedades" src={ bgDev } alt="" />
        
        <div className="main__first__section__title__novedades d-flex align-items-end justify-content-center">
          <div className="main__first__section__title__container__novedades  col-10 h-100 d-flex flex-column justify-content-center">
            <h1 className="fs-1 font-0 text-semibold" >Enterate de todas las tendencias <br /> del real estate.</h1>
            <div className="col-12 div_bottom_arrow d-flex d-lg-block justify-content-center justify-content-lg-start">
                <a onClick={(e) => e.preventDefault() + scrollTo("novedades")}>
                    <span className="icon-arrow-solid"></span>
                </a>
            </div>
            {/* <p className=" text-end fs-14">
              Una obra de arquitectura que funde diseño, arte y tecnología con un estilo único.
              <br /> Un proyecto que interpreta la ciudad con un respeto absoluto por su entorno.
              <br /> La síntesis perfecta entre pasado, presente y futuro.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  )
}

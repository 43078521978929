import React from 'react'
import { useSelector } from 'react-redux';
import WrapperTopContent from '../wrapperTopContent';
import desarrollo from '../../assets/images/foto-en-desarrollo.jpg';
import listosParaVivir from '../../assets/images/foto-listos-para-vivir.jpg';

export const FirstSection = ({status}) => {

  const data_dev = useSelector((store) => store.developments.developments).data;
  const actions = useSelector((store) => store.actions);

  const page = 'emprendimientos';

  const actionsInPage = actions?.actions?.filter(action => action?.section.toLowerCase() === page && (action?.small_type === "SIE" || action?.small_type === "IEN" || action?.small_type === "VEN"))[0];

  const hasActionsPage = (actionsInPage) ? Boolean(actionsInPage?.section.toLowerCase() === page) : undefined;
  const hasActionsSdr = (actionsInPage) ? Boolean(actionsInPage?.small_type === "SIE") : undefined;
  const hasActionsImg = (actionsInPage) ? Boolean(actionsInPage?.small_type === "IEN") : undefined;
  const hasActionsVid = (actionsInPage) ? Boolean(actionsInPage?.small_type === "VEN") : undefined;

  const imgEl = <img className="main__first__section__background__desarrollos" 
    src={status === 4 ? desarrollo : listosParaVivir } alt="emprendimiento" />;
  const textEl = <div className="main__first__section__title__desarrollos d-flex align-items-end justify-content-center">
    <div className="main__first__section__title__container__desarrollos align-items-lg-end  col-12 h-100 d-flex flex-column justify-content-center">
      <h1 className="fs-1 font-0 text-bold">
        {status === 4 ? "LOS NUEVOS QUARTIER QUE SE AVECINAN" : "LOS QUARTIER QUE YA PODÉS DISFRUTAR"}</h1>
    </div>
  </div>; 

  return (
    <section className="main__first__section__desarrollos d-flex" id="firstSection">
      <div className="main__first__section__container__desarrollos">
        { hasActionsPage && (hasActionsSdr || hasActionsImg || hasActionsVid)
            ? <WrapperTopContent 
                pageProp={page}
                dataProp={data_dev}
                actionsProp={actions}
                videoImgReplaceProp={status === 4 ? desarrollo : listosParaVivir } 
                videoTextReplaceProp={status === 4 ? "LOS NUEVOS QUARTIER QUE SE AVECINAN" : "LOS QUARTIER QUE YA PODÉS DISFRUTAR"} /> : null }
        { hasActionsPage && hasActionsImg ? textEl : null }
        { !hasActionsPage || !(hasActionsSdr || hasActionsImg || hasActionsVid) 
            ? <> {imgEl} {textEl} </> : null }
      </div>
    </section>
  )
}

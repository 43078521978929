import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';




export const MenuMobile = ({ isClicked }) => {
  const settings = useSelector((store) => store.settings);
  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const view = curLocation ? curLocation.split("/")[0] : ''
  const filters = useSelector((store) => store.properties.filters);
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const checkActive = (_pathname) => {
    if((_pathname === 'ventas' || _pathname === "alquileres") && pathname.includes('venta') || pathname.includes('alquiler')){
      if (filters.operation){
        if(filters?.operation?.includes(_pathname)){
            return true;
        }
      }
    }
    else{
      if(pathname === (_pathname)){
          return true;
      }
    }
    return false
  }


  useEffect(() => {
  },[curLocation])

  const staticBody = () => {
    var body = document.body;
    body.classList.toggle("overflow-hidden-mobile");
  }

  const novelties = useSelector((store) => store.novelties.novelties);
  
  return (
    <div className={`header__menu__mobile d-lg-none ${ isClicked ? 'active' : '' }`}>
      <div className="header__menu__container h-100 d-flex justify-content-center">
        <ul className="d-flex flex-column align-items-center justify-content-center p-0">
          <li onClick={() => staticBody()} className=" fs-5 mt-5">
            <Link to={(  settings.base_url + "")} className={"" + (curLocation === '' ? "active" : '')}>Inicio</Link> 
          </li>
          <li onClick={() => staticBody()} className=" fs-5 mt-5">
            <Link to={( settings.base_url + "nosotros")} className={"" + (checkActive("nosotros") ? 'active' : '')}>Nosotros</Link> 
          </li>
          <li onClick={() => staticBody()} className={"fs-5 mt-5 " + (checkActive("ventas") ? 'active' : '')}>
            <Link to={ settings.base_url + "ventas" } className={" " + (checkActive("ventas") ? 'active' : '')}>Propiedades en venta</Link>
          </li>
          <li onClick={() => staticBody()} className={"fs-5 mt-5 " + (checkActive("alquileres") ? 'active' : '')}>
            <Link to={ settings.base_url + "alquileres" } className={" " + (checkActive("alquileres") ? 'active' : '')} >Propiedades en Alquiler</Link>
          </li>
          <li onClick={() => staticBody()} className={"fs-5 mt-5 " + (checkActive("listos-para-vivir") ? 'active' : '')}>
            <Link  to={ settings.base_url + "listos-para-vivir" } className={" " + (checkActive("listos-para-vivir") ? 'active' : '')}>Listos para vivir</Link>
          </li>
          <li onClick={() => staticBody()} className={"fs-5 mt-5 " + (checkActive("en-desarrollo") ? 'active' : '')}>
            <Link to={( settings.base_url + "en-desarrollo")} className={" " + (checkActive("en-desarrollo") ? 'active' : '')}>En desarrollo</Link> 
          </li>
          <li onClick={() => staticBody()} className={"fs-5 mt-5 " + (checkActive("novedades") ? 'active' : '') + (novelties?.length === 0 ? 'd-none' : '')}>
            <Link to={( settings.base_url + "novedades")} className={" " + (checkActive("novedades") ? 'active' : '')}>Novedades</Link> 
          </li>
        </ul>
      </div>
    </div>
  )
}

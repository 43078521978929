import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from "react-router-dom";
import { ButtonAnimation } from "../buttons/btnWhitRadius/ButtonAnimation";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'
import img1 from '../../assets/images/distrito.png'
import img2 from '../../assets/images/ImagenHome.jpeg'


import homeAbout1 from '../../assets/images/home-about1.jpg'
import homeAbout2 from '../../assets/images/home-about2.jpg'
import homeAbout3 from '../../assets/images/home-about3.jpg'
import circlePrimary from '../../assets/images/elipse_bg_home_primary.svg';
import { getDevelopmentsAction } from "../../redux/developmentsDucks";

function About() {
  const dispatch = useDispatch();
  const settings = useSelector(store => store.settings)
  const {data} = useSelector(store => store.developments.developments)
    useEffect(() => {
    AOS.init({ duration : 2000});
    }, []);

  return (
    <>
        <section className="div_distrito position-relative">
            <div className="container-fluid">
               <div className="row">
                <div className="col-12 col-lg-4 offset-lg-1 div-first d-flex flex-column"> 
                    <img src={img1} alt="title-distrito" /> 
                    <h4 className="font-1 mt-4 ">Un proyecto que te conecta al futuro, listo para vivir.</h4>
                    <div className="div_btn">
                        {/* <a className="button-common font-0 text-bold" href="desarrollo/42530/distrito-quartier">[Conocelo]</a> */}
                        <Link className="button-common font-0 text-bold" to="desarrollo/42530/distrito-quartier">Conocelo</Link>
                    </div>
                </div>
                
                <div className="col-6 div-second d-none d-lg-block px-lg-0">
                  <img src={img2} alt="" />
                    </div>
               </div>
            </div>
           
        </section>
    </>

  )
}

export default About;

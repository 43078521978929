import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getPropertiesFeaturedAction } from "../../redux/propertiesDucks";
import { get_cover } from "../../functions/functions_dev";
import OwlCarousel from 'react-owl-carousel2';
import Card from '../development-card'
import img1 from '../../assets/images/quartier.png'



import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ButtonGhostTwo } from "../buttons/btnGhost/ButtonGhost";
import zoom from '../../assets/images/signo_mas.svg';

function Lotes() {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getPropertiesFeaturedAction())
    },[])

    const settings  = useSelector((store) => store.settings);
    const {data} = useSelector(store => store.developments.developments);
    const data_dev = useSelector((store) => store.developments.developments).data;
    const codes = useSelector((store) => store.developments.stateCodes);
    const loading = useSelector(store => store.developments.loading );

    const Slider = useRef()

    const options = {
        items: 1,
        center:false,
        nav: true,
        navClass:false,
        dots:false,
        margin:0,
        rewind: true,
        // autoplay: true, // True en caso de que haya +4 destacados
        // autoplayTimeout:3000,
        loop:false, // True en caso de que haya +4 destacados 
        responsive : {
            0:{
                nav:false,  
                items:1,
                dots:true,
                // dotsEach:true,
                // dotsData:true,
                margin:10,
            },
            920:{
                items: 3,
                margin:20,
                center:false,
                nav: true,
            }
        }
    };

    const changePositions = (data) => {
        return data.filter(element => element.construction_status !== 2 && element.construction_status !== 1)
    }


  return data_dev ?  (
        <section className="projects_desarrollo">
            <div className="container-fluid">
                <div className="row m-auto justify-content-start">
                    <div className="col-12 text-center mb-5">
                        <img src={img1} alt="" />
                        <h2 className="font-0 text-regular mt-4 fs-3">EN DESARROLLO</h2>
                    </div>
                    {
                        !loading ? //Cargó?
                            data.meta.total_count > 0 ? // Hay resultados?
                            (data.objects).filter(dev => dev.construction_status === 4).map((element,index) => (        
                                index < 3 ?
                                
                                <div className={"col-12 col-md-12 col-lg-4 col-xl-4 mb-4"} key={index}>
                                    <Card element={element}/>
                                </div>
                                    
                                :""                       
                                )) 
                                : //No hay resultados
                                <div className="my-5 pb-lg-5 pb-3">
                                    <h4 className="text-center text-black">No se encontraron resultados en el estado de construcción seleccionado.</h4>
                                </div>
                            : //Están cargando los desarrollos
                            <>
                                <div class="loader relative">
                                    <div class="page-lines">
                                        <div class="container">
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                        </div>
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                        </div>
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                            <div class="line"></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="loader-brand">
                                        <div class="sk-folding-cube">
                                        <div class="sk-cube1 sk-cube"></div>
                                        <div class="sk-cube2 sk-cube"></div>
                                        <div class="sk-cube4 sk-cube"></div>
                                        <div class="sk-cube3 sk-cube"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                    <div className="col-12 text-center div_btn">
                        <a className="button-common text-bold" href="/en-desarrollo">Ver todos</a>
                    </div>
                </div>
            </div>
        </section>
  ) : ''
}

export default Lotes;
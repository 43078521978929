import React , {useEffect} from 'react'


// -- Componentes -- //
import Main from '../components/novedades/main'
import Blogs from '../components/novedades/blogs'
import { FirstSection } from '../components/novedades/FirstSection.jsx'
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { SectionForm } from '../components/section-form/SectionForm'
import { Whatsapp } from '../components/Whatsapp'
import { useDispatch } from 'react-redux';
import {getTagsAction, getNoveltiesAction} from '../redux/noveltiesDucks'
import { getActionsAction } from '../redux/actionsDucks';


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Novedades(){

    const dispatch = useDispatch() 

    useEffect(() => {
      // dispatch(getTagsAction())
      dispatch(getNoveltiesAction())
      dispatch(getActionsAction())
    },[])

    ScrollToTopOnMount()
    return(
        <div className="novedades">
          <Header />
          <Sidebar/>
          <Whatsapp />
          <FirstSection  />
          <div className="layout">
            <Main />
            <div className="content">
                <Blogs />
                <SectionForm />
            </div>
          </div>
        </div>
    )
}

export default Novedades

import React from "react";
import { useSelector } from "react-redux";
import WrapperTopContent from "../wrapperTopContent";
import mobileImage from "../../assets/images/bg_mobile.jpg"

function Main() {

    const data_dev = useSelector((store) => store.developments.developments).data;
    const actions = useSelector((store) => store.actions);
    const page = 'inicio';

    return data_dev ?(
        <main className="main d-lg-block"  style={{ width: "100%"}}>
           <WrapperTopContent 
                pageProp={page}
                dataProp={data_dev}
                actionsProp={actions}
                videoImgReplaceProp={mobileImage} />
        </main>
  )
  :
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import { getLocationProperty,getPrice,searchTour,searchYoutube,get_short_desc,addFavorites,checkFavorite, getfavorites, getOperations, getType, getFakeAddres} from "../../functions/functions_property";
import { updateFields,formTokko } from '../../redux/contactDucks'
import { getFavoritesAction,clearFavoritesAction } from '../../redux/propertiesDucks'
import {getGroup_CustomTag} from '../../functions/functions_dev'
import {SRLWrapper} from 'simple-react-lightbox'
import "react-lightbox-pack/dist/index.css";
import { useLightbox } from 'simple-react-lightbox'
import toast, { Toaster } from 'react-hot-toast';
// import { checkFavorite } from '../../functions/helper.favorites';


import Map from "../map";

import dblCircles from '../../assets/images/circles_bg_propiedad.svg';
import { data } from "jquery";
import { useHistory, useLocation } from "react-router-dom";

const Data = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const settings = useSelector((store) => store.settings);
  const property = useSelector((store) => store.properties.property).data;
  const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
	const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);
  const { openLightbox, closeLightbox } = useLightbox()

  var href = window.location.href;

  const location = useLocation().pathname.split("/")[1];

  console.log(href)


  const [open,setOpen] = useState(false)
  const [opens,setOpens] = useState(false)

  const toastCheck = (id,fake_address) => {
    if(!checkFavorite(id,"prop",settings.short_name))
        return toast.success(fake_address + " agregada a tus favoritos") 
    else
        return toast.success(fake_address + " eliminada de tus favoritos")
  }

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};
  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900
    },
    buttons: {
      iconColor: '#ffff'
    },
    caption: {
      captionColor: '#fff',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '300',
      captionTextTransform: 'uppercase'
    },
    progressBar: {
      height: '20px',
      fillColor: '#191919',
      backgroundColor: 'white'
    }
};

  const [max_caracters, setMax_caracter] = useState(200);
  const [hidden,setHidden] = useState(true)
  const [text,setText] = useState('')
  
  const [datos, setDatos] = useState({
      nombre:'',
      apellido:'',
      email:'',
      text:'',
  })
  const handleInputChange = (event) => {
      setDatos({
          ...datos,
          [event.target.name] : event.target.value, 
      })
  }
  const sendForm = (event) => {
    event.preventDefault()
    dispatch(formTokko('Propiedad',property.fake_address,property.id))
    document.getElementById("formContact").reset()
    setText("¡Mensaje enviado correctamente!")
  }
  const checkActive = (id) => {
    setListFavorites(getfavorites("prop",settings.short_name))
    dispatch(clearFavoritesAction())
    for (const iterator of getfavorites("prop",settings.short_name)) {
        //Por cada uno de los favoritos, traigo su respectiva data de la api
        dispatch(getFavoritesAction(iterator))
    }
  } 
  const getBluePrints = () => {
    if(property.photos.filter(element => element.is_blueprint === true)){
        return (property.photos.filter(element => element.is_blueprint === true))
    }
    return []
  } 

  const direccion = (status) => {
    if (status === 6) {
      return "listos-para-vivir"
    }else {
      return "en-desarrollo"
    }
  } 

  const convert_location = (location) => {
    switch (location) {
      case 'alquiler-temporario':
        return 'alquileres-temporarios'
      case 'venta':
        return 'ventas'
      case 'alquiler' :
        return 'alquileres'
      default : return location
    }
  }

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  var iconSeparate = "| ";

  return property ? (
    <section className="data" id="proyectos">
      <div className="container-fluid primer_bloque">
        <div className="row align-items-start">
          
          <div className="col-12">
            <div className="row">
              <div className="div_cabecera col-12 position-relative">
                {property?.development?.id > 0 ? 
                  <BreadCrumbs
                  props={[
                    { name: "Inicio", route: settings.base_url, location: "" },
                    { name: property?.development?.construction_status === 6 ? "LISTOS PARA VIVIR" : "EN DESARROLLO" , route: settings.base_url + direccion(property?.development?.construction_status), location: "" },
                    {
                      name: property?.development?.name,
                      route:'',
                      active:true
                    }
                  ]}
                />
                : 
                <BreadCrumbs
                   props={[
                    { name: "Inicio", route: "", location: "" },
                    {
                      name: location.charAt(0).toUpperCase() + location.slice(1),
                      route: settings.base_url + location,
                      location: location,
                    },
                    {
                      name: property.type.name,
                      route: settings.base_url + convert_location(location)  + "/" + property.type.name.replace(/ /g, "-").toLowerCase(),
                      location: location,
                    },
                    
                    {
                      name: property.fake_address,
                    },
                  ]}
                />
                }
              </div>
              <div className="primera_col col-12 col-lg-7 d-flex flex-column">
                <div>
                  <div className="d-block d-lg-flex row">
                    <div class="col-12 d-block d-lg-none mb-5">
                      <div class="row no-gutters justify-content-end">
                          <div class={"col-12 bar-share justify-content-end text-right align-items-start d-lg-flex"}>
                              <div className="content position-relative justify-content-end d-flex">
                                  <div class={"content-share justify-content-end d-flex align-items-center "  + (open ? ' active ' : '')}>
                                      <a class="d-inline d-lg-none icon-whatsapp" href={"https://api.whatsapp.com/send?&text=La siguiente propiedad te puede interesar: " + href} target="_blank" rel="noopener noreferrer"></a>
                                      <a class="d-lg-inline d-none icon-whatsapp" href={"https://api.whatsapp.com/send?&text=La siguiente propiedad te puede interesar: " + href} target="_blank" rel="noopener noreferrer"></a>
                                      <a class="icon-email" href={"mailto:?Subject=" + getFakeAddres(property) + "&body=La siguiente propiedad te puede interesar: " + href} target="_blank" rel="noopener noreferrer"></a>
                                  </div>
                                  <span  onClick={() => setOpen(!open)}  class={"icon-share share me-3 " + (open ? ' active ' : '')}>
                                  </span>
                              </div>
                              
                          </div>
                      </div>
                    </div>
                    <h2 className="col-12 col-lg-8 title_propiedad text-bold pt-lg-3 fs-4 text-center text-lg-start ">
                      {property.real_address}
                    </h2>
                    <div class="col-4 d-none d-lg-block">
                      <div class="row no-gutters justify-content-end">
                          <div class={"col-lg-4 col-12 bar-share justify-content-lg-end text-right align-items-start d-lg-flex"}>
                              <div className="content position-relative d-flex">
                                  <div class={"content-share d-flex align-items-center "  + (open ? ' active ' : '')}>
                                      <a class="d-inline d-lg-none icon-whatsapp" href={"https://api.whatsapp.com/send?&text=La siguiente propiedad te puede interesar: " + href} target="_blank" rel="noopener noreferrer"></a>
                                      <a class="d-lg-inline d-none icon-whatsapp" href={"https://api.whatsapp.com/send?&text=La siguiente propiedad te puede interesar: " + href} target="_blank" rel="noopener noreferrer"></a>
                                      <a class="icon-email" href={"mailto:?Subject=" + getFakeAddres(property) + "&body=La siguiente propiedad te puede interesar: " + href} target="_blank" rel="noopener noreferrer"></a>
                                  </div>
                                  <span  onClick={() => setOpen(!open)}  class={"icon-share share " + (open ? ' active ' : '')}>
                                  </span>
                              </div>
                              
                          </div>
                      </div>
                    </div>

                  </div>
                  <div className="div-info-prop mt-5 mb-5">
                    {property.age === 0 ? <span className="font-1 fs-10 type-prop estreno">A estrenar</span> :''}
                    {property.operations.map((item)=>(
                      <span className={`font-1 fs-10 ` + item.operation_type }>{item.operation_type}</span>
                    ))}
                    <span className="font-1 fs-10 type-prop">{property.type.name}</span>
                  </div>
                  <div className="div_items row mt-5">
                    <div className={"col-6 d-lg-block mt-4 " + (property.room_amount > 0 ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-prop-type-light me-4   "}></i>
                        {property.room_amount > 0 ? property.room_amount + " Ambiente" + (property.room_amount > 1 ? 's' : '') : '0 Ambientes'} 
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.suite_amount  > 0 ? "" : 'd-none')}>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-rooms-light me-4   "}></i>
                        {property.suite_amount  > 0 ? "" + property.suite_amount + ' Habitación' + (property.suite_amount > 1 ? 'es' : '') : "Sin Habitaciones"} 
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.bathroom_amount > 0 ? '' : 'd-none') }>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-bathroom-light me-4"}></i>
                        {property.bathroom_amount > 0 ? "" + property.bathroom_amount + " Baño" + (property.bathroom_amount > 1 ? 's' : '') : 'Sin Baños' } 
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.parking_lot_amount > 0 ? "" : 'd-none')}>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-parking-light me-4   "}></i>
                        {property.parking_lot_amount > 0 ? "" + property.parking_lot_amount + ' Cochera' + (property.parking_lot_amount > 1 ? 's' : '')  : 'Sin Cocheras'}
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.toilet_amount > 0 ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-toilette me-4"}></i>
                        {property.toilet_amount > 0 ? "" + property.toilet_amount + ' Toilettes' + (property.toilet_amount > 1 ? 's' : '')  : 'Sin Toilettes'}
                      </div>
                    </div>
                    <div className="col-6 mt-4 ">
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-expenses me-4   "}></i>
                        {property.expenses > 0 ? "$" + property.expenses + ' Expensas' : 'Sin Expensas'}
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.floors_amount > 0 ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-floors me-4   "}></i>
                        {property.floors_amount > 0 ? "" + property.floors_amount + ' Planta' + (property.floors_amount > 1 ? 's' : '')  : 'Sin Plantas'}
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.orientation !== null ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-orientation me-4   "}></i>
                        {property.orientation !== '' ? 'Orientación: ' + property.orientation : 'Sin Orientación'}
                      </div>
                    </div>
                    <div className="col-6 mt-4 ">
                      <div className="item-extra d-flex  fs-9">
                        <i className={"icon-age me-4   "}></i>
                        {property.age === 0 ? 'A estrenar' : property.age === -1 ? 'En construcción' : + property.age + ' años de antiguedad'}
                      </div>
                    </div>    
                  </div>
                </div>
                
                  
                {/* SUPERFICIES */}
                    <div className="d-block mt-5 mt-lg-auto mb-5">
                      <div className=" pt-lg-5 row align-items-center ">
                        <div className={"item-extra d-flex surface  align-items-center  fs-9 col-6 " + (!property.total_surface && 'd-none')}>
                          {property.total_surface > 0 ?
                            <div className="d-flex align-items-center justify-content-start font-0 text-regular ">
                              <i className="icon-measure me-4"></i>
                              { new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " M2 totales. " }
                            </div>
                            : ''} 
                        </div>
                        <div className={"item-extra surface d-flex  align-items-center  fs-9 col-6 " + (!property.roofed_surface && !property.surface && !property.semiroofed_surface && !property.unroofed_surface && 'd-none')}>
                          {property.roofed_surface > 0 ?
                          <div className="d-flex align-items-center justify-content-start font-0 text-regular ">  
                           <i className="icon-measure me-4"></i>
                            {new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " M2 cubiertos. "}
                          </div>
                          : ''} 
                          {/* {property.unroofed_surface > 0 ?
                          <div className="d-flex align-items-center justify-content-start">
                            {new Intl.NumberFormat("de-DE").format(parseInt(property.unroofed_surface).toFixed(0)) + " m2 descubiertos. "}
                          </div>:''}
                          {property.semiroofed_surface > 0 ?
                          <div className="d-flex align-items-center justify-content-start">
                            {new Intl.NumberFormat("de-DE").format(parseInt(property.semiroofed_surface).toFixed(0)) + " m2 semicubiertos. "}
                          </div>:''} 
                          {property.surface > 0 ? 
                          <div className="d-flex align-items-center justify-content-start">
                            {new Intl.NumberFormat("de-DE").format(parseInt(property.surface).toFixed(0)) + " m2 de terreno. " }
                          </div>
                          : ''}  */}
                          {/* <span className="separate-items">{iconSeparate}</span> */}
                        </div>
                      </div>
                    </div>
                  {/* SUPERFICIES */}
               
              </div>
              <div className={"segunda_col col-12 col-lg-5  mt-4 mt-lg-0 price-container "} >
                <div className={"content-description d-flex flex-column align-items-start "}>
                    
                  <p className="text-content fs-8">
                    {get_short_desc(property.description, max_caracters).map((element) => (
                      <>
                        {element}
                        <br />
                      </>
                    ))}

                    {/* {max_caracters} */}

                    <a
                      className="cursor-pointer d-block   pt-5 fs-9 see_description"
                      onClick={() =>
                        max_caracters === 200
                          ? setMax_caracter(9999)
                          : setMax_caracter(200)
                      }
                    >
                      {property.description.length > 200 ?
                      max_caracters === 200 ? 
                          "Ver más"
                        : "Ver menos"
                        : ""}
                    </a>
                  </p>
                  <hr />
                  <div className="content-price mt-auto">
                  {property.operations.map((item)=>(
                    <h3 className={`text-bold fs-2 ${item.operation_type + (property.web_price ? '' : ' min-size')}`}>{property.web_price ? getPrice(item.prices[0]) : 'Precio a consultar'}</h3>
                  ))}
              </div>
              
              </div>
              
            </div>

            {/* SUPERFICIES */}

            {/* <div className="col-12 tercera_col d-none d-lg-block">
                <div className="div_items d-block">
                    <div className="pt-lg-5 d-flex align-items-center ">
                      <div className={"item-extra surface fs-9 " +  (property.roofed_surface > 0 || property.surface > 0 || property.semiroofed_surface > 0 || property.unroofed_surface > 0 ? "d-flex" : "d-none")}>
                        <i className="icon-measure me-4"></i>
                          {property.roofed_surface > 0 ?  
                          <div>
                              {new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " m2 cubiertos. "}
                          </div>
                          : ''} 
                          {property.unroofed_surface > 0 ?
                          <div>
                            {new Intl.NumberFormat("de-DE").format(parseInt(property.unroofed_surface).toFixed(0)) + " m2 descubiertos. "} 
                          </div>
                        :''}
                          {property.semiroofed_surface > 0 ?
                            <div>
                                {new Intl.NumberFormat("de-DE").format(parseInt(property.semiroofed_surface).toFixed(0)) + " m2 semicubiertos. " }
                            </div>
                          :''} 
                          {property.surface > 0 ? 
                          <div>
                              {new Intl.NumberFormat("de-DE").format(parseInt(property.surface).toFixed(0)) + " m2 de terreno. " }
                          </div>
                          : ''} 
                      </div>
                    </div>
                    <div className="pt-lg-5 d-flex align-items-center ">
                      <div className={"item-extra surface  fs-9 " + (property.total_surface > 0 ? 'd-flex' : 'd-none')}>
                        <i className="icon-measure me-4"></i>
                        {property.total_surface > 0 ?
                            <div>
                                {new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " m2 totales. " }
                            </div>
                            : ''} 
                        
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
          </div>
          {/* SUPERFICIES */}
          {/* //DESKTOP TAGS*/}
  
          {/* ///////////////// */}
          
        </div>
      </div>
      <div className={"segundo_bloque  mt-lg-0 " + (property.custom_tags.length > 0 || property.tags.length > 0 ? ' ': 'd-none')}>
        <div className="container-fluid ">
          <div className="amenities">
            <div className={"" + (property.tags.length > 0 ? ' ': 'd-none')}>
              <h3 className=" mb-5 font-0 text-bold fs-4">Amenities.</h3>
              <div className="row hilera_amenities">
                {property.tags.map( (tag, index)=>(
                  <div key={index} className="col-6 col-lg-3 ">
                    <div className="item"><i className="icon-check"></i>{tag.name}</div>
                  </div>   
                ))}
              </div>
            </div>
            <h3 className={"mt-5 mb-5 " + (property.custom_tags.length > 0 ? ' ': 'd-none')}>Amenities adicionales.</h3>
            <div className="row hilera_amenities">
              {property.custom_tags.map( (tag, index)=>(
                <div key={index} className="col-6 col-lg-3 ">
                  <div className="item"><i className="icon-check"></i>{tag.name}</div>
                </div>   
              ))}
                     
            </div>
          </div>
        </div>
      </div>
      {/* Planos */}
      {getBluePrints().length > 0 ? 
        <div className="tercer_bloque">
          <section className=" container-fluid m-auto position-relative" id="planos">
          <h2 className="fs-3 text-bold text-center text-uppercase mb-5" >planos</h2>
          <div class="js-projects-gallery">
            <section class="clients section position-relative">
              <div class="images-grid-section">
                    <SRLWrapper options={options}>
                      <div class="project one image-grid-element">
                          {
                            getBluePrints().map((element,index) => (
                              <a className="cursor-pointer project-plano" onClick={() => openLightbox(index)}>
                                <figure style={{margin:"0"}}>
                                    <div class="div_zoom"><i className="icon-plus-light"></i></div>
                                    <img alt="" src={element.original}  />
                                    <figcaption>
                                      <div class={"project-zoom "}></div>
                                      <h4 class="project-category">
                                        {element.description}
                                      </h4>
                                    </figcaption>
                                </figure>
                              </a>
                            ))
                          }
                      </div>      
                    </SRLWrapper>
              </div>              
            </section>
          </div>
          </section>
        </div>
      :''}
      {/* Mapa */}
      <div className="cuarto_bloque pt-5">
        <section className="text-center" id="ubicacion">
            <div >
                <h2 className="fs-3 text-bold text-center text-uppercase" >Ubicación</h2>
                {/* <h5 className=" fs-2 text-regular text-uppercase mt-5" >{property.fake_address ? (property.fake_address +  ", " + property.location.short_location.split(" | ")[0]) + "." : ''}</h5> */}
                <h5 className="font-1 fs-5 text-regular text-center" style={{marginTop:"1.5rem", color: "#C1BCBC"}}>{property.fake_address ? (property.fake_address + ", " + property.location.name + ".") : ''}</h5>

            </div>
            <div className="prop-map">
                <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
            </div>
        </section>

      </div>
      
   
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};
export default Data;

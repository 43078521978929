import React from 'react'

import bgDev from '../../assets/images/background-tower.jpg';

export const FirstSection = () => {
  return (
    <section className="main__first__section__novedad d-flex" id="firstSection">
      <div className="main__first__section__container__novedad">
        <img className="main__first__section__background__novedad" src={ bgDev } alt="" />
        
        <div className="main__first__section__title__novedad d-flex align-items-end justify-content-center">
          <div className="main__first__section__title__container__novedad  col-10 d-flex flex-column justify-content-center">
            
          </div>
        </div>
      </div>
    </section>
  )
}


import { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import zoom from '../assets/images/signo_mas.svg';
import ImageNotFound from "../assets/images/image-not-found.jpg";

const dev_Card = (props) => {
    
    

    const {element} = props;

    // const settings  = useSelector((store) => store.settings);

    function get_cover(photos){
        return photos.find(element => element.is_front_cover) ? photos.find(element => element.is_front_cover) : photos[0] ? photos[0] : ImageNotFound;
    }

    const stateCodes = [
        {
            id:0,
            name:"Desconocido"
        },
        {
            id:1,
            name:"Desconocido"
        },
        {
            id:2,
            name:"Reuniendo Inversores"
        },
        {
            id:3,
            name:"En pozo"
        },
        { 
            id:4,
            name:"En Construcción"
        },
        {
            id:5,
            name:"Construcción Detenida"
        },
        {
            id:6,
            name:"Construcción Terminada"
        }
    ]

    const searchStateCode = (id,stateCodes) => {
        return stateCodes.find(element => element.id === id)
    }

    

    return props.element ? (
        <div class={"card__project " }>
            {/* {console.log(element)} */}
            <div className={"vendido font-0 text-semibold fs-3"  + (element.custom_tags.find(item => item.name === "100vendido") ? ' d-block ' : ' d-none ')} >100% <br /> VENDIDO</div>
            <div className={"vendido disponibles font-0 text-semibold fs-3"  + (element.custom_tags.find(item => item.name === "disponibles") ? ' d-block ' : ' d-none ')} >ÚLTIMAS <br /> UNIDADES <br /> DISPONIBLES</div>
            {/* <div className={"ultimas_novedades font-0 text-semibold fs-3" + (element.custom_tags.find(item => item.name === "disponibles") ? ' d-block ' : ' d-none ')}>ÚLTIMAS <br /> UNIDADES <br /> DISPONIBLES</div> */}
            { true  ?
                <Link to={"desarrollo/" + element.id + "/" + ( element ? (element.name).replace(/ /g,"-").toLowerCase() : '')} title={element.publication_title} >
                    <div class="card__project__container">
                        <div class="card__project__bg">
                            <img src={ get_cover(element.photos).original } alt="" />
                            {/* {
                                element.custom_tags.find(e => e.name === '100vendido') ?
                                <img src={settings.base_url + "img/vendido.png"} alt="" className="position-absolute absolute-top-right" />
                                :
                                ''
                            } */}
                            {/* <div class="card__title font-0 text-bold fs-5">
                                {element.name}
                            </div>
                            <div class="card__data__text font-0 text-regular fs-7 ">
                                <p className="mb-0">{element.address}, {element.location.name}</p>
                            </div>
                            
                            <div className='card__text__card font-1 fs-7 text-uppercase'>
                                <p className={(searchStateCode(element.construction_status,stateCodes).name === "En pozo" ? 'enPozo' : 'enConstruccion')}>
                                {searchStateCode(element.construction_status,stateCodes).name}    
                                </p>
                            </div>
                            
                            <div class="card__project__data">
                                <div class="card__data__image">
                                    <span>+</span>
                                </div>
                            </div> */}
                        </div>
                        <div className='body-hover text-center'>
                            <span className='icon-plus-light'></span>
                            <div class="card_text font-0 text-semibold d-block">
                                <p className="mb-0">{element.address} </p><p className=''>{element.location.name}</p>
                            </div>
                        </div>
                        <div class="card__title font-0 text-bold fs-7">
                            {element.name}
                        </div>
                    </div>
                </Link>

            :''
            }
        </div>
    ): ''
} 

export default dev_Card
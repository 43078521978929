import { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import {getDevelopmentsFilterCustomTag} from '../../functions/functions_dev'

import {updateFiltersDevelopment, getDevelopmentsAction} from '../../redux/developmentsDucks'
import { ButtonAnimationPrimary } from '../buttons/btnWhitRadius/ButtonAnimation'
import zoom from '../../assets/images/zoom.png';
import Card from '../development-card'
import Breadcrumbs from '../breadcrumbs';

const Lotes = ({status}) =>{
    const dispatch = useDispatch();
    const {data} = useSelector(store => store.developments.developments);
    const loading = useSelector(store => store.developments.loading );
    const settings  = useSelector((store) => store.settings);
    const codes = useSelector((store) => store.developments.stateCodes);
    const filtersDevelopment = useSelector((store) => store.developments.filtersDevelopment).status;

    useEffect(()=>{
    },[filtersDevelopment])

    useEffect(()=>{
        if (status) {
            dispatch(updateFiltersDevelopment(status))
        }
    },[])

    

    return  (
        <section className="projects">
            {/* <div className="blog-tags d-lg-flex flex-lg-row justify-content-lg-center align-items-baseline">
                <a onClick={() => dispatch(updateFiltersDevelopment(''))} className={"btn-filters-blog mb-3 mb-lg-0" + (!filtersDevelopment ? ' active ' : '')}>
                    <ButtonAnimationPrimary text="Todos"></ButtonAnimationPrimary>
                </a>
                <a onClick={() => dispatch(updateFiltersDevelopment(3))} className={"btn-filters-blog mb-3 mb-lg-0" + (filtersDevelopment === 3 ? ' active ' : '')}>
                    <ButtonAnimationPrimary text="En pozo"></ButtonAnimationPrimary>
                </a>
                <a onClick={() => dispatch(updateFiltersDevelopment(4))} className={"btn-filters-blog mb-3 mb-lg-0" + (filtersDevelopment === 4 ? ' active ' : '')}>
                    <ButtonAnimationPrimary style={{width: '25rem'}} text="En construcción"></ButtonAnimationPrimary>
                </a>
                <a onClick={() => dispatch(updateFiltersDevelopment(6))} className={"btn-filters-blog mb-3 mb-lg-0" + (filtersDevelopment === 6 ? ' active ' : '')}>
                    <ButtonAnimationPrimary text="Terminado"></ButtonAnimationPrimary>
                </a>
                <p className='fs-2'>→</p>
            </div> */}
        <div className="container-fluid div_breadcrumbs">    
            <Breadcrumbs   
                props={[
                    { name: "Inicio", route: settings.base_url, location: "" },
                    { name: status === 4 ? "en desarrollo" : "LISTOS PARA VIVIR", route:  settings.base_url + "desarrollos", location: "", active:true },
                ]}
            />
        </div>
        <div className='container-fluid'>
            <div className="row m-auto justify-content-start">
                {
                    !loading ? //Cargó?
                        data.meta.total_count > 0 ? // Hay resultados?
                            (data.objects).sort((a,b)=>b.id-a.id).map((element,index) => (
                                <div className={"col-12 col-md-12 col-lg-4 col-xl-4 mb-4"} key={index}>
                                    <Card element={element}/>
                                </div>
                            )) 
                            : //No hay resultados
                            <div className="my-5 pb-lg-5 pb-3">
                                <h4 className="text-center text-black">No se encontraron resultados en el estado de construcción seleccionado.</h4>
                            </div>
                        : //Están cargando los desarrollos
                        <>
                            <div class="loader relative">
                                <div class="page-lines">
                                    <div class="container">
                                    <div class="col-line col-xs-4">
                                        <div class="line"></div>
                                    </div>
                                    <div class="col-line col-xs-4">
                                        <div class="line"></div>
                                    </div>
                                    <div class="col-line col-xs-4">
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </div>
                                    </div>
                                </div>
                                <div class="loader-brand">
                                    <div class="sk-folding-cube">
                                    <div class="sk-cube1 sk-cube"></div>
                                    <div class="sk-cube2 sk-cube"></div>
                                    <div class="sk-cube4 sk-cube"></div>
                                    <div class="sk-cube3 sk-cube"></div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    </section>
    )
    
}

export default Lotes
export const getFeatured =  (data) => {
    return data.objects.filter(element => element.is_starred_on_web === true)
}

export const shortLocation = (full_location,position) => {
    var split = full_location.split("|")
    return split[position]
}

export const getVideo = (videos,type) => {
  const filter_yt = videos.filter(element => element.provider === type)
  if(filter_yt.length === 0) return null
  return filter_yt[filter_yt.length - 1];
}

export const getTour = (videos,type) => {
  const filter_tour = videos.filter(element => element.type === type)
  if(filter_tour.length === 0) return null
  return filter_tour[filter_tour.length - 1];
}

export const shortFull_Location = (full_location) => {
    var split = full_location.split("|");
    return split[2] + "| " + split[1];
  }

export const getPricesUnityPrice = (unity_development) => {
    var valueUnity = [];
    for (const iterator of unity_development.objects) {
      valueUnity.push(iterator.operations[0].prices[0].price);
    }
    return [Math.min.apply(null, valueUnity), Math.max.apply(null, valueUnity)];
  }

export const get_short_desc = (description,max_caracters) => {
    if (description?.length > max_caracters) {
      return description?.slice(0, max_caracters) + '...';
    }
    return description;
};

import ImageNotFound from "../assets/images/image-not-found.jpg";
export const get_cover = (photos) => {
  return photos.find(element => element.is_front_cover) ? photos.find(element => element.is_front_cover) : photos[0] ? photos[0] : ImageNotFound;
}

export const get_shortDescription = (description) => {
  const spliter = description.split('.');
  var res = {
    title:'',
    description:'',
    photos:[
      '',
      '',
      '',
      '',
    ]
  }
  var offset = 0
  for (let i = 0; i < spliter.length; i++) {
    if(res.title.length <= 80)
      res.title += spliter[i] + "."
    if(res.description.length <= 100)
    {
      res.description += spliter[i] + "."
      offset++;
    }
  }
  for (let j = 0; j < 4; j++){
    for (let i = 0; i < spliter.length; i++) {
      if(res.photos[j].length <= 100 && i>=offset){
        res.photos[j] += spliter[i] + '.'
        offset++;
      }
    }
  }
  return res;
}

export const getGroup_CustomTag = (customTags,groupName) => {
  return customTags.find(element => element.group_name === groupName);
}

export const getDevelopmentsFilterCustomTag = (developments,groupName,customTag,reverse = false) => {
  // reverse ON = si queremos traes todas las que no tengan ese custom tag
  let filter_developments = []
  if(reverse){
    developments.map(element => {
      // element.group_name === groupName && 
      // console.log(element.custom_tags.find(elements => elements.name === customTag))
      if(!element.custom_tags.find(element => element.name.toLowerCase() === customTag.toLowerCase()))
        filter_developments.push(element)
    })
    // filter_developments = developments.filter(element => element.custom_tags.find(element => element.group_name.toLowerCase() === groupName.toLowerCase() && element.name.toLowerCase() !== customTag.toLowerCase()))
  }
  else{
    developments.map(element => {
      if(element.custom_tags.find(element => element.group_name === groupName && element.name === customTag))
        filter_developments.push(element)
    })
    // filter_developments = developments.filter(element => element.custom_tags.find(element => element.group_name.toLowerCase() === groupName.toLowerCase() && element.name.toLowerCase() === customTag.toLowerCase()))
  }
  return filter_developments
}

export const group_Unities = (data,filter = 0, financeTagsFilter = "noTags") => {
  const array = [
  ];
  for (let i = 0; i < data.length; i++) {
    // Armo la nueva unidad en un objeto, separo piso de la unidad en el titulo
    // if(data[i].id === 447053){console.log(data[i])}
    if((filter === data[i].room_amount || filter === 0)){
      let object = {}
      const price = data[i].web_price === true ? data[i].operations[0].prices[0].price : 'A Consultar'
      const currency = data[i].web_price === true ? data[i].operations[0].prices[0].currency : ''
      const customTags = data[i].custom_tags && data[i].custom_tags.length > 0 ? data[i].custom_tags : []
      if(data[i].id !== 741886){
        object = {
          floor: data[i].real_address.split("-")[0],
          unity: {
            address:data[i].real_address.split("- ")[1],
            id:data[i].id,
            status:data[i].status,
            type:data[i].type.name,
            location:data[i].location.name,
            surface:data[i].roofed_surface,
            surface_total:data[i].total_surface,
            rooms:data[i].room_amount,
            operation:data[i].operations[0].operation_type,
            price:price,
            currency:currency,
            customTags:customTags
          }
        }
      }
      // // Verifico si existe el piso o lo tengo que crear
      const finder = array.find(element => element.floor === object.floor)
      // Se verifica el filtro de financiamiento
      if(financeTagsFilter === "noTags" || 
        ( financeTagsFilter === "customTags,Financiación" && data[i].custom_tags?.find(c => c.id === 24675) ||
          financeTagsFilter === "notCustomTags" && !data[i].custom_tags?.find(c => c.id === 24675) )) {
        if(array.find(element => element.floor === object.floor)){
          finder.unities.push(object.unity)
        }
        else{
          array.push({
            floor:object.floor,
            unities:[object.unity]
          })
        }
      }
    }
  }

  let pisos = []
  let subsuelos = []
  let nivel = []
  let duplex = []


  let response= [];
  
  for (const iterator of array) {
    if(iterator.floor.split(" ")[0].toLowerCase().includes("piso")){
      if(iterator.floor.includes("PB")){
        pisos.unshift(iterator);
      }
      else{
        pisos.push(iterator);
      }
    }
    if(iterator.floor.split(" ")[0].toLowerCase().includes("subsuelo")){
      subsuelos.push(iterator);
    }
    if(iterator.floor.split(" ")[0].toLowerCase().includes("nivel")){
      nivel.push(iterator);
    }
    if(iterator.floor.split(" ")[0].toLowerCase().includes("dúplex")){
      duplex.push(iterator);
    }
  }
  pisos = pisos.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})
  subsuelos = subsuelos.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})
  duplex = duplex.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})

  for (const iterator of subsuelos) {
    response.push(iterator);
  }
  for (const iterator of nivel) {
    response.push(iterator);
  }
  for (const iterator of pisos) {
    response.push(iterator);
  }
  for (const iterator of duplex) {
    response.push(iterator);
  }
  return response;
  // return array.sort(function(a, b){return a.floor - b.floor});
}
import axios from 'axios'
import { getSectionFromCode, getTypeFromCode, removeExpiratedActions } from '../functions/functions_actions'

// constantes
const dataInicial = {
    actions:[],
    loading_actions:[],
}

// types
const LOADING_ACTIONS = "LOADING_ACTIONS";
const GET_ACTIONS = 'GET_ACTIONS'

// reducer
export default function actionsReducer(state = dataInicial, action){
    switch(action.type){
        case LOADING_ACTIONS :
             return {...state,loading_actions:true,};
        case GET_ACTIONS:
            return {
                ...state,
                actions : action.payload,
                loading_actions:false
        };
        default:
            return state
    }
}

// actions
export const getActionsAction = (env) => async (dispatch, getState) => {
    // if(getState().actions.loading_actions || !env?.APISERVER){return null}
    dispatch({type: LOADING_ACTIONS})
    try {
        const res = await axios.get('https://mediacore.app/api/push/acciones/16/activas/')
        const actions = res.data
        const expirated_out = removeExpiratedActions(actions)
        let parsed_actions = []
        for(const action of expirated_out){
            let aux = action;
            aux.small_type = aux.type
            aux.small_section = aux.section
            aux.type = getTypeFromCode(aux.small_type)
            aux.section = getSectionFromCode(aux.small_section)
            parsed_actions.push(aux)
        }
        dispatch({
            type: GET_ACTIONS,
            payload: expirated_out
        })
    } catch (error) {
        console.log(error)
    }

}

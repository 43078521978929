import React from "react"
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'




const Experiencia = () => {
    useEffect(() => {
        AOS.init({ duration : 2000});
        }, []);
    return (
        <div className="div_experiencia">
            <div class="container-fluid fluid-cont">
                <div class="row">
                    <div className="col-6">
                        <h2 class="number-count font-0 text-bold">      
                            {<CounterNumber value={50} />}
                        </h2>
                        <h2 className="text-count font-0 text-bold">
                        AÑOS DE <br /> EXPERIENCIA.
                        </h2>
                    </div>
                    <div className="col-5 offset-1">
                        <h2 class="number-count font-0 text-bold">     
                            {<CounterNumber value={20} />}
                        </h2>
                        <h2 className="text-count font-0 text-bold">
                                PROYECTOS <br /> EN CARTERA.
                        </h2>
                    </div>
                
                </div>
            </div>
        </div>
    )
    function CounterNumber({ value }) {
        return (
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => {
              return (
                <div>
                  {isVisible ? (
                    <CountUp
                      start={0}
                      end={value}
                      duration={2.75}
                      delay={0}
                      separator="."
                    >
                      {({ countUpRef, start }) => <span ref={countUpRef} />}
                    </CountUp>
                  ) : (
                    <span>0</span>
                  )}
                </div>
              );
            }}
          </VisibilitySensor>
        );
    }
      
}

export default Experiencia
import React, { useEffect, useState } from "react";
import { clearPropertiesAction, getListFilters,getPropertiesAction } from "../redux/propertiesDucks";

import { useSelector , useDispatch} from "react-redux";

// -- Componentes -- //

import Main from "../components/propiedades/main";
import Grid from "../components/propiedades/grid";
import { Header } from "../components/header/Header";
import Filter from "../components/propiedades/filter";
import { SectionForm } from '../components/section-form/SectionForm';
import { changeLocationAction } from "../redux/themeDucks";
import { useLocation } from "react-router-dom";
import { Whatsapp } from '../components/Whatsapp'


function Resultados() {
  const dispatch = useDispatch();
  const { data } = useSelector((store) => store.properties.listFilters);
  const theme = useSelector((store) => store.theme);
  const settings = useSelector((store) => store.settings);
  const [activeFilter, setActiveFilter] = useState(false);
  const curLocation = useLocation()



  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = settings.seo.title
  }, [data]);

  useEffect(() => {
    const checkLocation = () => {
      dispatch(getListFilters()); 
    };
    checkLocation();
    window.scrollTo(0, 0);
    dispatch(changeLocationAction(curLocation.pathname.split("/")[1]));
    dispatch(clearPropertiesAction());
  },[curLocation])

  // useEffect(() => {
  //   dispatch(getListFilters())
  //   dispatch(getPropertiesAction())
  // }, []);

  return (
    <>
    <div className="Resultados">
        <Header />
        <Whatsapp />
      {/* {data ?  */}
      <>
        <Main setActiveFilter={setActiveFilter} activeFilter={activeFilter} />
        <Filter setActiveFilter={setActiveFilter} activeFilter={activeFilter}></Filter>
        <Grid></Grid> 
      </>
      {/* :
        <div className="loading-section">
          <div className="lds-dual-ring"></div>{" "}
        </div>
      } */}
    </div>
    <SectionForm />
    </>
  ) 
}

export default Resultados;

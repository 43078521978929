
import { useSelector } from "react-redux";
import { getVideo } from "../../functions/functions_dev";

const Video = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);

  // Render
  return data ? 
  <>
      <section className={"video " + (getVideo(data.videos,"youtube") ? '' : 'd-none')}>
        <div className="container-fluid pb-5">
            <div className="div_heard_video text-center mb-5">
              <h2 className="font-0 fs-3 text-bold text-uppercase">video</h2>
              <h2 className="font-0 fs-5 text-semibold">{getVideo(data.videos,"youtube")?.title}</h2>
            </div>
            <div className="div_video">
              <iframe className="video-development" style={{border:"1px solid"}} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"width="100%" height="600px" type="text/html" src={getVideo(data.videos,"youtube")?.player_url + "?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0" + "&origin=https://youtubeembedcode.com"}><div><small><a href="https://youtubeembedcode.com/nl/">youtubeembedcode nl</a></small></div><div><small><a href="https://flickrembedslideshow.com">Flickr embed</a></small></div></iframe>
            </div>
        </div>
      </section>
      <section className={"video pt-5 " + (getVideo(data.videos,"hauzd") ? '' : 'd-none')}>
        <div className="container-fluid">
            <div className="div_heard_video text-center mb-5">
              <h2 className="font-0 fs-3 text-bold text-uppercase">Tour 360</h2>
              <h2 className="font-0 fs-5 text-semibold">{getVideo(data.videos,"hauzd")?.title}</h2>
            </div>
            <div className="div_video">
              <iframe className="video-development" style={{border:"1px solid"}} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"width="100%" height="600px" type="text/html" src={getVideo(data.videos,"hauzd")?.player_url}></iframe>
            </div>
        </div>
      </section>
  </>
     : (
      <div className="loading-section">
        <div className="lds-dual-ring"></div>{" "}
      </div>
    );
};

export default Video;

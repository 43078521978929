import React, { useState, useEffect } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";
import { updateFields,formTokko } from '../../redux/contactDucks'
import toast, { Toaster } from 'react-hot-toast';

import { ButtonAnimation } from '../buttons/btnWhitRadius/ButtonAnimation';

import { useLocation } from 'react-router-dom';

export const Form = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const settings = useSelector(store => store.settings)
  const [disabled,setDisabled] = useState(true);
  const [text,setText] = useState("")

  const development = useSelector((store) => store.developments.development).data;
  const property = useSelector((store) => store.properties.property).data;

  const [datos, setDatos] = useState({
    nombre:'',
    apellido:'',
    text:'',
    email:'',
    telefono:'',
  })
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name] : event.target.value, 
    })
  }
  const sendForm = (event) => {
    event.preventDefault()
    if(development){
      dispatch(formTokko('Consulta Emprendimiento',development.name,development.id,true))
    }
    if(property){
      dispatch(formTokko('Consulta Propiedad',property.address,property.id))
    }
    if(!property && !development){
      dispatch(formTokko('Consulta Web',null,null))
    }
    document.getElementById("formContact").reset()
    // setText("Su consulta fue enviada correctamente. Un asesor se pondrá en contacto con ud. a la brevedad.")
    toast.success("Su consulta fue enviada correctamente. Un asesor se pondrá en contacto con ud. a la brevedad.");
  }

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  // useEffect(() => {
  //   setText("")
  // },[location])

  // const checkTest = () => {
  //   if(development){
  //     console.log("development")
  //   }
  //   if(property){
  //     console.log("property")
  //   }
  //   if(!property && !development){
  //     console.log("contacto")
  //   }
  // }

  return (
    <form className="col-lg-12 " onSubmit={sendForm} id="formContact">
      <div className="row-field row">
        
          <div className=" div_input col-12 col-lg-4">
            <input
              type="text "
              className="form-control pb-3"
              name="nombre"
              placeholder="Nombre*"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className=" div_input col-12 col-lg-4"  id="d-only">
            <input
              type="text"
              className="form-control pb-3"
              name="apellido"
              placeholder="Apellido"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="div_input col-12 col-lg-4">
            <textarea
              className="form-control  pb-3"
              name="text"
              placeholder="Mensaje*"
              onChange={handleInputChange}
              autoComplete="off"
              maxLength=""
            ></textarea>
          </div>
          <div className="div_input col-12 col-lg-4" style={{marginBottom:"8px"}} >
            <input
              type="text "
              className="form-control pb-3"
              name="email"
              required
              placeholder="Email*"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="div_input col-12 col-lg-4">
            <input
              type="text"
              className="form-control pb-3"
              name="telefono"
              placeholder="Telefono"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-4 d-lg-flex d-none"></div>
          <div className='col-12 col-lg-4 div_obligatorio'>
            <p className='font-1 text-regular fs-11 text-start'>*Campos obligatorios</p>
          </div>
          <div className="col-12 offset-lg-4 col-lg-4">
            <div className='align-items-start mt-5 mt-lg-0'>
              <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
              <div className="d-flex mt-4">
                <button disabled={disabled} className="boton_contact fs-7 text-uppercase font-0 text-bold button-common m-auto m-lg-0">
                  Enviar
                  {/* <span className="boton_text fs-7 text-uppercase font-0 text-bold">Enviar</span> */}
                  {/* <span className="">
                      <i className="icon-arrow-solid  fs-3"></i> 
                  </span> */}
                </button>
                
                {/* <span className={text === "" ? "d-none" : ""}>{text}</span> */}
                </div>
                <Toaster
                  position="top-center"
                  containerClassName=""
                  containerStyle={{}}
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      maxWidth: '60vw',
                    },
                  }}
                  
                />
            </div>
          </div>
          
          
        
      </div>
    </form>
  )
}

import React from 'react'
import arrowTop from '../../assets/images/arrow_up.svg'; 
import elipse_bg from '../../assets/images/elipse_bg.svg'
import { Form } from '../form/Form'
import Map from '../map'
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import {Link, useLocation} from "react-router-dom";

const latitud = {latitud: -34.57565921221931, longitud: -58.420479848902474}

export const SectionForm = () => {
  const { data } = useSelector((store) => store.developments.development);
  const unity = useSelector((store) => store.properties.property).data;
  const settings  = useSelector((store) => store.settings);
  const location = useLocation();

  const checkView = () => {
    if(location.pathname.split(settings.base_url).length === 1){
      return false
    }
    if(location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo' || location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      return true
    } else {
      return false
    }
  }

  const [name,setName] = useState('');

  useEffect(() => {
    if(data && location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo'){
      setName(data.name.replace(/ /g, "-").toLowerCase())
    }
    else if(unity && location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      setName(unity.development?.name?.replace(/ /g, "-").toLowerCase())
    }
    else{
      setName('')
    }
  },[location,data,unity])

  

  return  (
    <section id="contacto" className="div_section_form position-relative">
            <div className="container-fluid ">
                <div className="div_news_container">
                    <div className="row ">
                        <div className='div_title col-12 offset-lg-4 col-lg-8 text-start '>
                          <h2 className="font-0 fs-5 text-bold">¿QUERÉS MÁS INFORMACIÓN?</h2>
                          <a onClick={() => window.scrollTo( 0, 0 )}  className="section__five__arrow__top d-xl-block">
                            <i className="icon-arrow-light"></i>
                          </a>
                        </div>
                        <div className="div_first mb-2 col-12 col-lg-4 text-center text-lg-start d-none d-lg-block">

                          <p className='fs-10 font-1 text-regular mt-4'>Distrito Quartier <br /> Comodoro Pedro Zanni 351 (Loft Norte PB 4), CP 1104, <br /> CABA Buenos Aires, Argentina. <br /> Teléfono: +54 11 3991 3084</p>
                          <a className='fs-10 font-1 text-regular' target="_blank" href="mailto:comercial@unitequartier.com">comercial@unitequartier.com</a>
                        </div>
                        
                        <div className="div_second mb-2 col-12 col-lg-8 position-relative">
                          <div className='row'>
                          <Form />
                          </div>
         
                          
                            
                        </div>

                        <div className="div_first mb-2 col-12 col-lg-4 text-center d-block d-lg-none">

                          <p className='fs-10 font-1 text-regular'>Distrito Quartier <br /> Comodoro Pedro Zanni 351 (Loft Norte PB 4), CP 1104, <br /> CABA Buenos Aires, Argentina. <br /> Teléfono: +54 11 3991 3084</p>
                          <a className='fs-10 font-1 text-regular' target="_blank" href="mailto:comercial@unitequartier.com">comercial@unitequartier.com</a>
                        </div>
        
                    </div>
                </div>
            </div>
            
           
        </section>
    
  )
}

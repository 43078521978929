import React, { useRef }  from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { getActionByPage, getActionByType } from '../../../../functions/functions_actions';
import Arrow from '../../../../assets/images/arrow_slider.svg';
import { informCustomEvent } from '../../../../functions/functions_analytics';
import ImageNotFound from "../../../../assets/images/image-not-found.jpg";

const BannerTOP = ({page, actions}) => {

    const slider = useRef()
    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),page)
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),page)
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),page)

    const goTo = () => {
        if (video_action[0].url_destiny) {
            informCustomEvent("PUSH_" + video_action[0].small_section + "_" + video_action[0].small_type + "_" + video_action[0].name)
            window.open(video_action[0].url_destiny + video_action[0].text_utm, '_blank', 'noopener,noreferrer')
        }
    }

    const getCover = (element) => (element !== null) ? element : ImageNotFound;

    return (
        slider_action.length > 0 || video_action.length > 0 || image_action.length > 0 //Caso Actions video/slider
        ?   <>
                {
                    slider_action.length > 0 ?
                        <div className="full-slider" id="section-galery-home">
                            <div id="dev-secuencial">
                                <div className="slider" id="dev-gallery">
                                    <OwlCarousel
                                        loop={true}
                                        dots={false}
                                        center={true}
                                        items={1}
                                        margin={0} 
                                        autoplay={true}
                                        rewind={true}
                                        ref={slider}
                                        autoplayTimeout={slider_action[0]?.transition_time * 1000}>
                                        { slider_action[0].files.map((element, index) => (
                                            <div className="art" key={index}>
                                                <div className="container">
                                                    <img src={getCover(element.file)} alt="Slider gallery image" />
                                                    <div className="opacity"></div>
                                                    <div className="text-content position-relative d-flex justify-content-center align-items-end flex-column">
                                                        <a href={element.url_destiny + slider_action[0].text_utm} target={'_blank'} 
                                                           className={"link-gallery" + (!element.url_destiny ? ' d-none' : '')}
                                                           onClick={() => informCustomEvent("PUSH_" + slider_action[0].small_section + "_" + slider_action[0].small_type + "_" + slider_action[0].name)}></a>
                                                    </div>
                                                </div>
                                            </div> ))}
                                    </OwlCarousel>
                                    <div className="arrow__container d-flex justify-content-between">
                                        <div onClick={() => (slider.current.prev())} 
                                             className="cursor-pointer">
                                                <img src={Arrow} alt="Anterior" />
                                        </div>
                                        <div onClick={() => (slider.current.next())} 
                                             className="cursor-pointer ml-4"
                                             style={{transform:"rotate(180deg)"}}>
                                                <img src={Arrow} alt="Siguiente" />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
                {
                    video_action.length > 0 ? 
                        <div className={"banner-main " + (video_action[0].url_destiny === '' ? 'cursor-default' : 'cursor-pointer')}  
                            onClick={() => goTo() }>
                                { <video loop autoPlay muted >
                                    <source src={video_action[0]?.files?.filter(video => video.device ==='desktop')?.[0]?.file ?? ''} type="video/mp4" />
                                    </video> }
                        </div> : null
                }
                {
                    image_action.length > 0 ?
                        <div id="home-main" onClick={() => image_action[0].url_destiny ? window.open(image_action[0].url_destiny, '_blank', 'noopener,noreferrer') + informCustomEvent("PUSH_" + image_action[0].small_section + "_" + image_action[0].small_type + "_" + image_action[0].name): ''} className={"banner-main " + (image_action.length === 0 || image_action[0]?.url_destiny === '' ? 'cursor-default' : '') }  style={{zIndex:"9",backgroundImage:'url(' + (image_action[0]?.files?.[0]?.file + ")")}} ></div> : null
                }
            </>
        : null
    )
}

export default BannerTOP;

// Component usage
// <BannerTOP 
//    page="inicio"  >  page where it's used
//    actions={actions.actions} />  >  actions object from parent